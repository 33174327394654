html, body {
    margin: 0;
    padding: 0;
    height: 100%;
}

* {
  user-select: none
}

input{
  user-select: text !important;
}

body {
    background-color: $body-bg;
    font-family: 'Fira Sans', sans-serif;
}

.bg-void {
    background-color: $bg-void!important;
}

.bg-body {
    background-color: $body-bg!important;
}

.clickable {
    cursor: pointer;
}

.nowrap {
    white-space: nowrap;
}

.breakword {
    white-space: break-word;
}

.valign-text-bottom {
  vertical-align: text-bottom;
}
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0.5); 
    border: 2px solid transparent;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0,0,0,0.8); 
  }
  
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none; }
  
  ::-webkit-scrollbar-corner {
    background-color: transparent; 
}

iframe {
  border: 0;
  display: block;
  width: 100%;
}

*[tabindex] {
  cursor: pointer;
}

input[tabindex], textarea[tabindex] {
  cursor: text;
}