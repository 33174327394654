@mixin color-svg($color) {
    color: $color;

    rect[stroke], circle[stroke], polyline[stroke], polygon[stroke], line[stroke], ellipse[stroke], path[stroke], g[stroke] {
        stroke: $color;
    }

    rect, circle, polyline, polygon, line, ellipse, path, g {
        fill: $color;
    }

    rect[stroke='none'], circle[stroke='none'], polyline[stroke='none'], polygon[stroke='none'], line[stroke='none'], ellipse[stroke='none'], path[stroke='none'], g[stroke='none'] {
        stroke: none;
    }

    rect[fill='none'], circle[fill='none'], polyline[fill='none'], polygon[fill='none'], line[fill='none'], ellipse[fill='none'], path[fill='none'], g[fill='none'] {
        fill: none;
    }

    g[fill='none'] {
        rect, circle, polyline, polygon, line, ellipse, path {
            fill: none;
        }
    }
}