.context-menu {
  display: none;
  max-width: 30rem;

  background-color: $context-menu-bg-color;
  color: $context-menu-color;

  z-index: $zindex-popover;
  border-radius: $border-radius;
  @extend .shadow;

  .icon svg {
    @include color-svg($context-menu-color);
  }

  .icon {
    @extend .me-2;
  }

  .context-annotationType-menu-item {
    border-top: 1px solid darken($context-menu-bg-color, 10%);
    transition: background-color 200ms linear;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px;

    &:hover,
    &:focus {
      background-color: var(--accentColour);
    }

    &:last-child {
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }

    .colour-token {
      height: 15px;
      width: 15px;
      border-radius: 15px;
      display: inline-block;
      margin-left: 10px;
      margin-right: 10px;
      vertical-align: middle;
    }

    .type-name {
      margin-right: 5px;
    }
  }

  .context-menu-item {
    @extend .ps-2;
    @extend .pe-2;
    @extend .pt-2;
    @extend .pb-2;
    border-top: 1px solid darken($context-menu-bg-color, 10%);
    transition: background-color 200ms linear;

    &:hover,
    &:focus {
      background-color: var(--accentColour);
    }

    &:last-child {
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }
}