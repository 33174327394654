/* Default app branding colours and settings. Will be the same for all libraries unless overridden by a theme or outide the library context. */

/* Accents */
$accent: #1c67d8;
$darker-accent: darken($accent, 20%);

/* Icons */
$icon-active-colour: #2b7fc4;
/* Search meter */
$meter-fill-primary-colour: red;
/* Accents cont. */
$accent-invert: $white;
$secondary-accent: lighten($accent, 10%);
$accent-colour-text: $accent;
/* Main navigation */
$first-navbar-bg: darken($accent, 10%); // Background colour of the left most vertical navigation bar, with app-level buttons (brands, user, etc.)
$first-navbar-colour: darken($accent-invert, 10%); // Text colour of the left most vertical navigation bar, with app-level buttons (brands, user, etc.)
$second-navbar-bg: $gray-300; // Background colour of the book vertical navigation bar
/* Loading overlay */
$loading-modal-bg-colour: rgba($black, 0.7);
$loading-modal-colour: $white;
$loading-modal-opque-bg-colour: $body-bg;
$loading-modal-opaque-colour: $gray-600;

:root {
  --loadingModalBgColour: #{$loading-modal-bg-colour}; // Background colour for loading overlays when they are set to "translucent" mode. All current overlays use this mode.
  --loadingModalColour: #{$loading-modal-colour}; // Text colour for loading overlays when they are set to "translucent" mode. All current overlays use this mode.
  --loadingModalOpaqueBgColour: #{$loading-modal-opque-bg-colour}; // Background colour for loading overlays when they are set to "opaque" mode. Currently no loading overlays are Opaque (5/4/2020).
  --loadingModalOpaqueColour: #{$loading-modal-opaque-colour}; // Text colour for loading overlays when they are set to "opaque" mode. Currently no loading overlays are Opaque (5/4/2020).
  --firstNavbarBg: #{$first-navbar-bg}; // Background colour of the main navbar (containing the user and settings icons and the libraries).
  --firstNavbarColour: #{$first-navbar-colour}; // Text colour of the main navbar (containing the user and settings icons and the libraries).
  --secondNavbarBg: #{$second-navbar-bg}; // Background colour for the seocndary navbar. This is the one that contains the AnnotationType/Annotation/Favourite/History/Search icons.
  --secondNavbarColour: #{$first-navbar-colour}; // Text colour for the secondary navbar. This is the one that contains the AnnotationType/Annotation/Favourite/History/Search icons.
  --navItemActiveColour: #{$accent}; // Used as the active item colour for the AnnotationType/Annotation/Favourite/History/Search icons in the second navbar.
  --accentColour: #{$accent}; // Used in various places as the main colour. Context menu highlight, button glows, the spinner on book tabs, search mode highlight, etc.
  --secondaryAccent: #{$secondary-accent}; // ToC items that arent the active node. Generally this is a lighter version of the accent colour.
  --accentInvert: #{$accent-invert}; // This represents the colour the app will use when presenting text inside of controls that have the accent colour. Should be the closest inverse possible to the accent. e.g. white invert on black accent.
  --loadingBarFillPrimaryColour: #{$icon-active-colour}; // Represents the colour to be used for library title downloads.
  --loadingBarBackPrimaryColour: #{$white}; // Represents the colour to be used as the background for the empty part of the download bar.
  --meterFillPrimaryColour: #{$meter-fill-primary-colour}; // Represents the colour used for the search result relevancy gauge.
  --dropdownActiveTextColour: #{$accent-invert}; // colour used for the text of the selected dropdown item. Such as in suggestions or other various dropdowns.
  --infoHeaderColour: #{$accent}; // colour used for the moreInfo headers and links.
  --infoHeaderInvert: #{$accent-invert}; // Inverse colour to use for the text within the moreInfo header on selection.
  --tooltipPrimaryColour: #{$accent}; // Colour to be used for tooltips.
  --tooltipPrimartInvert: #{$white}; // Colour to be used for tooltop text. Should generally be an invert of the primary.
  --tableOfContentsHighlightColour: #{$accent}; // Colour to be used for the currently active ToC header.
  --tableOfContentsSecondayHighlightColour: #{$secondary-accent}; // Colour to be used for the "breadcrump" ToC headers.
  --tableOfContentsInvert: #{$white}; // Invert colour to use for ToC header text.
  --loginLinksColour: #{$accent}; // colour of link button text. This can be used to darken the link colours.
  --accentTextColour: #{$accent-colour-text}; // Colour used for text elements that should be the brands color. These include the tab text, the "available/owned" titles text
  --selectedTabTextColor: #{$accent}; // Colour used for the currently selected book tab text.
  --multiTitleSearchSelectedTitleColour: #{$darker-accent}; // Colour for the "glow" effect applied to selected book tiles during multi title search.
}
