.blackText {
    color: black;
}

.purchaseSummary {
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
    width: 100%;
    position: relative;

    .orderDetails {
        width: 60%;
        display: flex;
        flex-flow: row wrap;
        padding: 15px;
        height: 100%;
        overflow: scroll;

        .cover {
            margin: 15px;
            min-width: 7rem;
            min-height: 9.5rem;
        }

        .prodName {
            margin-top: 25px;
            word-wrap: break-word;
        }

        .prodSub {
            word-wrap: break-word;
        }

        .headers {
            width: 100%;
            display: flex;
            flex-flow: row nowrap;

        }

        .floatingWidget {
            width: 100%;
        }
    }

    .payBox {
        width: 40%;
        height: 100%;
        margin-left: 15px;
        margin-top: 40px;
    }

    .closeBtn {
        right: 5px;
        position: absolute;
    }
}


.purchaseSummary {
    @include media-breakpoint-down(lg) {
        display: flex;
        flex-flow: column-reverse nowrap;
        width: 100%;
        height: initial;

        .orderDetails {
            width: 100%;
            display: flex;
            flex-flow: row wrap;
            padding: 15px;
            flex: 1;
            overflow: visible;
            height: initial;

            .cover {
                margin: 15px;
                min-width: 7rem;
                min-height: 9.5rem;
            }

            .prodName {
                margin-top: 25px;
                word-wrap: break-word;
            }

            .prodSub {
                word-wrap: break-word;
            }

            .headers {
                width: 100%;
                display: flex;
                flex-flow: row nowrap;

            }

            .floatingWidget {
                width: 100%;
            }

        }

        .payBox {
            width: 100%;
            height: 100%;
            margin: 0px;
        }

        .closeBtn {
            transform: translateX(10px);
            top: 0;
        }
    }
}

.payBox {
    * {
        box-sizing: border-box;
    }

    body,
    html {
        background-color: #f6f9fc;
        font-size: 18px;
        font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
        margin: 0;
    }

    label {
        color: #6b7c93;
        font-weight: 300;
        letter-spacing: 0.025em;
    }

    button {
        white-space: nowrap;
        border: 0;
        outline: 0;
        display: inline-block;
        height: 40px;
        line-height: 40px;
        padding: 0 14px;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
        color: #fff;
        border-radius: 4px;
        font-size: 15px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.025em;
        background-color: #6772e5;
        text-decoration: none;
        -webkit-transition: all 150ms ease;
        transition: all 150ms ease;
        margin-top: 10px;
    }

    button:hover {
        color: #fff;
        cursor: pointer;
        background-color: #7795f8;
        transform: translateY(-1px);
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    }

    input,
    .StripeElement {
        display: block;
        margin: 10px 0 20px 0;
        max-width: 500px;
        padding: 10px 14px;
        font-size: 1em;
        font-family: "Source Code Pro", monospace;
        box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
            rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
        border: 0;
        outline: 0;
        border-radius: 4px;
        background: white;
    }

    input::placeholder {
        color: #aab7c4;
    }

    input:focus,
    .StripeElement--focus {
        box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
            rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
        -webkit-transition: all 150ms ease;
        transition: all 150ms ease;
    }

    .StripeElement.IdealBankElement,
    .StripeElement.FpxBankElement,
    .StripeElement.PaymentRequestButton {
        padding: 0;
    }

    .StripeElement.PaymentRequestButton {
        height: 40px;
    }

    .cvcNumber {
        max-width: 100px;
    }

    .cancel {
        background-color: red;
        margin-left: 15px;
    }

    .cancel:hover {
        color: #fff;
        cursor: pointer;
        background-color: darken(red, 5%);
        transform: translateY(-1px);
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    }

    .expiry {
        max-width: 100px;
    }
}

.transactionSuccess {
    svg {
        width: 200px;
    }

    label {
        margin-top: 15px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: fit-content;
    }

    @keyframes glowing {
        0% {
            box-shadow: 0 0 -10px green;
        }

        40% {
            box-shadow: 0 0 20px green;
        }

        60% {
            box-shadow: 0 0 20px green;
        }

        100% {
            box-shadow: 0 0 -10px green;
        }
    }

    .glow {
        animation: glowing 3000ms infinite;
        border-radius: 100px;
    }

    .glowBox {
        width: fit-content;
        margin: auto;
        display: block;
    }
}

.transactionError {
    svg {
        width: 200px;
        width: fit-content;
        margin: auto;
        display: block;
        animation: glowingError 3000ms infinite;
        border-radius: 10px;
    }

    label {
        margin-top: 15px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: fit-content;
    }

    @keyframes glowingError {
        0% {
            box-shadow: 0 0 -10px yellow;
        }

        40% {
            box-shadow: 0 0 20px yellow;
        }

        60% {
            box-shadow: 0 0 20px yellow;
        }

        100% {
            box-shadow: 0 0 -10px yellow;
        }
    }
}