
$icon-size: 17px;

.action-icon {
    opacity: 0.9;
}
.action-icon:focus, .action-icon:active {
    opacity: 1;
}

.btn .icon {
    float: left; 
    margin-right: $spacer;
}

.icon svg {
    width: $icon-size;
    @include color-svg($body-color);

    a & {
        @include color-svg($link-color);
    }

    a:hover & {
        @include color-svg($link-hover-color);
    }

    .nav-link.disabled & {
        @include color-svg($nav-link-disabled-color);
    }


    
    .badge & {
        width: $icon-size * 0.75;
    }

    
    @each $color, $value in $theme-colors {
        .badge-#{$color} & {
            @include color-svg(color-yiq($value));
        }
    }

    @each $color, $value in $theme-colors {
        .btn-#{$color} & {
          @include color-svg(color-yiq($value));
        }
      }
      
      @each $color, $value in $theme-colors {
        .btn-outline-#{$color} & {
          @include color-svg($value);
        }
    
        .btn-outline-#{$color}:hover & {
            @include color-svg(color-yiq($value));
          }
      }
}


@each $color, $value in $theme-colors {
    .icon.text-#{$color} svg, .text-#{$color} .icon svg {
        @include color-svg($value);
    }
}

.icon.text-muted svg, .text-muted .icon svg {
    @include color-svg($text-muted);
}


.action-icon {
    cursor: pointer;
}

.action-icon.disabled {
    opacity: 0.4;
    cursor: not-allowed;
}

