input,
textarea {
  -webkit-user-select: text;
}

.bookview-tabs {
  background-color: $body-bg;
}

.bookview-toggle-container {
  position: absolute;
  padding: 0.2rem 0.15rem;
  top: 0;
  left: 0;
  z-index: $zindex-overEverything;
}

.bookview {
  position: relative;
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 -10px var(--accentColour);
  }

  40% {
    box-shadow: 0 0 20px var(--accentColour);
  }

  60% {
    box-shadow: 0 0 20px var(--accentColour);
  }

  100% {
    box-shadow: 0 0 -10px var(--accentColour);
  }
}

.glow {
  animation: glowing 3000ms infinite;
  border-radius: 10px;
}

.btn-round {
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  padding: $spacer;
  text-align: center;
  vertical-align: middle;
  box-sizing: content-box;
}

.btn-fab {
  bottom: 16px;
  right: 16px;
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
  padding: $spacer;
  z-index: $zindex-drawer;

  & svg {
    height: 100%;
    width: 100%;
  }
}

.navbar-light {
  .icon {
    @include color-svg($navbar-light-color);
  }

  .navbar-nav {
    .nav-link .icon svg {
      @include color-svg($navbar-light-color);
    }

    .nav-link:hover .icon svg {
      @include color-svg($navbar-light-hover-color);
    }
  }
}

.dropdown-item.active,
.dropdown-item:active {
  .icon {
    @include color-svg(var(--accentInvert));
  }
}

.flip {
  @extend .d-flex;
  position: relative;
  overflow: hidden;

  .flip-panel {
    flex: 1;
  }

  .flip-back {
    visibility: hidden;
  }

  &.flip-one-column {
    .flip-panel-right {
      display: block;
      position: absolute;
      transform: translate(100%, 0);
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: $zindex-fixed;
      transition: transform 0.2s linear;
      @extend .border-start-0;
    }

    .flip-back {
      visibility: visible;
    }

    &.flip-open .flip-panel-right {
      transform: translate(0, 0);
    }
  }
}

.form-text-value {
  padding: $input-btn-padding-y $input-btn-padding-x;
  color: $input-color;
}

form.form-condensed {
  label {
    font-size: 80%;
    text-transform: uppercase;
    color: lighten($input-color, 10%);
    margin-bottom: $spacer * 0.2;
  }

  height: 100%;
  display: flex;
  flex-flow: column;

  .mb-3 {
    border-radius: $border-radius;
    background-color: $input-bg;
    border: $input-border-color solid $input-border-width;
    padding: $input-padding-y $input-padding-x;
    padding-bottom: 0;
    margin-left: 10px;

    &.form-group-grow {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      min-height: 300px;

      iframe {
        flex-grow: 1;
        height: auto;
        min-height: 0;
      }
    }

    .form-control {
      padding: 0;
      border: 0;
      border-bottom: 3px solid $input-border-color;
      border-radius: 0;
      margin-bottom: -1px;
      background-color: transparent;

      &:focus {
        box-shadow: none;
        border-bottom-color: var(--accentColour);
      }

      &.is-invalid,
      &:invalid {
        @extend .is-invalid;

        &:focus {
          box-shadow: none;
        }
      }

      &.is-invalid:focus + .invalid-tooltip,
      &:invalid:focus + .invalid-tooltip {
        opacity: 1;
      }
    }

    .invalid-tooltip {
      right: 0;
      margin-right: $input-padding-x;
      opacity: 0;
    }

    &:hover,
    &:focus {
      .invalid-tooltip {
        opacity: 1;
      }
    }
  }
}

.invalid-text {
  color: $danger;
}

.notification-container {
  position: fixed;
  top: 6rem;
  left: 0;
  right: 0;
  z-index: $zindex-notification;
  padding: $spacer;
  user-select: none;
  pointer-events: none;

  .notification-list {
    margin: 0 auto;
    @extend .container;

    > * {
      user-select: auto;
      pointer-events: auto;
    }
  }
}

.custom-checkbox.switch input:checked + .custom-checkbox-state {
  background-color: var(--accentColour) !important;
  box-shadow: 0 0 0 2px var(--accentColour) !important;
  border-color: var(--accentColour) !important;
}

.btn-outline-primary {
  color: var(--accentColour) !important;
  border-color: var(--accentColour) !important;

  svg {
    color: var(--accentColour) !important;
    border-color: var(--accentColour) !important;
    fill: var(--accentColour) !important;

    path {
      color: var(--accentColour) !important;
      border-color: var(--accentColour) !important;
      fill: var(--accentColour) !important;
    }
  }
}

.btn-outline-primary:hover {
  background-color: var(--accentColour) !important;
  color: var(--accentInvert) !important;

  path {
    color: var(--accentInvert) !important;
    border-color: var(--accentInvert) !important;
    fill: var(--accentInvert) !important;
  }
}

.btn-link {
  color: var(--loginLinksColour) !important;
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--accentInvert) !important;
  text-decoration: none;
  background-color: var(--accentColour) !important;
}

.primaryColoured {
  background-color: var(--accentColour) !important;
  color: var(--accentInvert) !important;
}

.__react_component_tooltip.primaryColoured {
  background-color: var(--tooltipPrimaryColour) !important;
  color: var(--tooltipPrimartInvert) !important;
}

.__react_component_tooltip {
  z-index: $zindex-tooltip !important;
}

.__react_component_tooltip.type-info.place-bottom:after {
  border-bottom-color: var(--accentColour) !important;
}

.__react_component_tooltip.type-info.place-right:after {
  border-right-color: var(--accentColour) !important;
}

.__react_component_tooltip.type-info.place-top:after {
  border-top-color: var(--accentColour) !important;
}

.__react_component_tooltip.type-info.place-left:after {
  border-left-color: var(--accentColour) !important;
}

.expander {
  clear: both;

  .expander-collider {
    overflow: hidden;
    max-height: 0;

    &.expander-open {
      max-height: none;
      overflow-x: visible; //Allow the dropdowns to overflow if they're in the form.
    }

    z-index: $zindex-modal;
  }

  .expander-body {
    background-color: $body-bg;
  }
}

.custom-checkbox {
  display: inline-block;
  position: relative;
  width: $custom-control-indicator-size;
  height: $custom-control-indicator-size;
  padding: 0;
  margin: 0;
  vertical-align: middle;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .custom-checkbox-state {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $custom-control-indicator-bg;
    transition: $custom-forms-transition;
    border-radius: $custom-checkbox-indicator-border-radius;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 60% 60%;
  }

  input:checked + .custom-checkbox-state {
    background-color: $custom-checkbox-bg-color-checked;
    background-image: $custom-checkbox-indicator-icon-checked;
  }

  input:indeterminate + .custom-checkbox-state {
    background-color: $custom-checkbox-indicator-indeterminate-bg;
    background-image: $custom-checkbox-indicator-icon-indeterminate;
  }

  input:disabled + .custom-checkbox-state {
    background-color: $custom-control-indicator-disabled-bg;
  }
}

.custom-checkbox.switch {
  width: $custom-control-indicator-size * 2;

  .custom-checkbox-state {
    background-image: none;
    border-radius: 20rem;
    box-shadow: 0 0 0 2px $custom-control-indicator-bg;

    &:before {
      position: absolute;
      content: "";
      height: $custom-control-indicator-size;
      width: $custom-control-indicator-size;
      left: 0;
      bottom: 0;
      transition:
        left 0.15s linear,
        background-color 0.15s ease-in-out;
      border-radius: 50%;
      background-color: $custom-checkbox-bg-color-checked;
    }
  }

  input:checked + .custom-checkbox-state {
    background-color: $custom-switch-bg-color-checked;
    border-color: $custom-switch-bg-color-checked;
    box-shadow: 0 0 0 2px $custom-switch-bg-color-checked;
    background-image: none;

    &:before {
      left: $custom-control-indicator-size;
      background-color: white;
    }
  }

  input:disabled + .custom-checkbox-state {
    background-color: $custom-control-indicator-disabled-bg;
  }
}

.message-dialog-item {
  .modal-header {
    border-bottom: 0;
  }

  .modal-body {
    padding-top: 0;
    p {
      margin-top: $spacer;
    }
  }

  &.deleteConfirm {
    padding: $spacer;
  }

  @include media-breakpoint-down(xs) {
    .modal-footer {
      flex: none !important;
      display: block !important;
      padding: 0;

      .btn {
        display: block !important;
        width: 100%;
        margin: 0 !important;
        border-radius: 0 !important;
      }

      .btn:last-child {
        border-bottom-left-radius: $border-radius !important;
        border-bottom-right-radius: $border-radius !important;
      }
    }
  }
}

.library-selection-icon {
  width: 3rem;
  height: 3rem;
  min-width: 3rem;
  min-height: 3rem;
  margin: 0.5 * $spacer;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  box-shadow:
    $box-shadow-sm,
    0 1px 0 0 rgba(0, 0, 0, 0.5);
  border-radius: $border-radius * 2;
  transition: box-shadow 0.2s linear;
  cursor: pointer;

  &.active {
    box-shadow:
      $box-shadow-sm,
      0 1px 0 0 rgba(0, 0, 0, 0.5),
      0 0 0 4px rgba($white, 0.5);
  }
}

.loading-modal-context {
  position: relative !important;
}

.loading-modal-context {
  position: relative !important;
}

.loading-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: $zindex-loading;
  background-color: var(--loadingModalBgColour);

  @extend .d-flex;
  @extend .justify-content-center;
  @extend .align-items-center;
  color: var(--loadingModalColour);
  text-align: center;

  .loading-modal-spinner {
    display: block;
    margin-bottom: $spacer;
    margin-left: auto;
    margin-right: auto;

    > svg {
      stroke: var(--loadingModalColour);
    }
  }

  &.loading-modal-opaque {
    background-color: var(--loadingModalOpaqueBgColour);
    color: var(--loadingModalOpaqueColour);
    z-index: $zindex-loading;

    .loading-modal-spinner > svg {
      stroke: var(--loadingModalOpaqueColour);
    }
  }
}

.static-modal-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .modal {
    display: block;
    position: absolute;
    overflow-y: auto !important;
  }

  .modal-backdrop {
    position: absolute;
    opacity: $modal-backdrop-opacity;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
}

.card-img-left {
  flex-direction: row;

  .card-img {
    width: auto;
    @extend .flex-shrink-0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.progress {
  background-color: var(--loadingBarBackPrimaryColour);

  .progress-bar {
    background-color: var(--loadingBarFillPrimaryColour);
  }
}

.progress.progress-indeterminate {
  $progress-bar-indeterminate-width: 20%;

  .progress-bar {
    min-width: $progress-bar-indeterminate-width;
    max-width: $progress-bar-indeterminate-width;
    width: $progress-bar-indeterminate-width !important;
    animation: progress-indeterminate 5s linear infinite;
  }

  @keyframes progress-indeterminate {
    0% {
      margin-left: 0%;
    }

    50% {
      margin-left: 100%-$progress-bar-indeterminate-width;
    }

    100% {
      margin-left: 0%;
    }
  }
}

.drawer-container {
  position: relative !important;
  overflow: hidden;

  .vertical-navbar {
    padding-top: calc($spacer/3);
  }

  .drawer {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    z-index: $zindex-drawer;
    transform: translate(-100%, 0);
    transition:
      transform 0.3s ease-in-out,
      width 0.3s ease-in-out;
    pointer-events: none;

    .drawer-content {
      pointer-events: all;

      height: 100%;
      overflow: auto;

      min-width: 300px;
      background-color: $gray-100;
      border-radius: $spacer $spacer 0 0;
      padding: $spacer;
    }

    & + .drawer-backdrop {
      opacity: 0;
      transition: opacity 0.3s ease-out;
    }

    &.drawer-show {
      transform: translate(0, 0);

      & + .drawer-backdrop {
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        position: absolute;
        z-index: $zindex-drawerBack;
        background: $black;
        opacity: 0.5;
      }
    }
  }

  &.drawer-container-to-top .drawer {
    transform: translate(0, 100%);
    height: 80%;
    bottom: 0;
    top: auto;

    &.drawer-show {
      transform: translate(0, 0);
    }

    width: 100%;
    max-width: none;
    min-width: none;

    .drawer-content {
      margin: 0 auto;
    }
  }
}

.dismissOverlay {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 90;

  &.hidden {
    display: none;
  }
}

.markdownContainer {
  table {
    width: 100%;
  }

  td,
  th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #ddd;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: var(--accentColour);
    color: var(--accentInvert);
  }
}

@import "cogniflow";

.separator {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}

.cogniflow-container {
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    display: none;
  }
}

.splash-page-context {
  position: relative;

  .splash-page {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: $black;

    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;

    z-index: $zindex-splashpage;
    background-origin: content-box;
  }

  .splash-page-status {
    color: $white;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: right;
    left: 0;
    right: 0;
    text-shadow:
      1px 0 0 #000,
      0 -1px 0 #000,
      0 1px 0 #000,
      -1px 0 0 #000;
    @extend .p-3;
  }
}

.scrubber {
  width: 20px;
  top: 0px;
  right: 0px;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: solid 1px lightgray;
  border-top: solid 1px lightgray;
  background-color: whitesmoke;

  .relativeInnerblock {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: hidden;

    .scrubber-handle {
      width: 100%;
      height: 50px;
      background-color: rgba(0, 0, 0, 0.3);
      margin: 0;
      position: absolute;
      transition: background-color 0.1s ease;
    }

    .scrubber-handle:not(.active):hover {
      background-color: rgba(0, 0, 0, 0.4);
    }

    .scrubber-handle.active {
      background-color: rgba(0, 0, 0, 0.5);
    }

    .scrubber-handle-tick {
      height: 3px;
      background-color: black;
      opacity: 0.3;
    }

    .scrubber-search-hit {
      height: 1px;
      background-color: red;
      position: absolute;
    }
  }
}

.scroll-navigation-container {
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
}

.scroll-navigation-modal {
  width: 800px;
  max-width: 90%;
  overflow: auto;
}

.scroll-navigation-modal .scroll-navigation-header:nth-child(even) {
  background-color: lightgrey;
}

.scroll-navigation-header:hover {
  background-color: darkgray !important;
}

.annotation-type {
  .colour-token {
    height: 15px;
    width: 15px;
    border-radius: 15px;
    display: inline-block;
    vertical-align: middle;
    border-top: 1px solid;
    border-color: $gray-400;
    margin-right: 5px;
    flex-shrink: 0;

    &.larger-token {
      height: 20px;
      width: 20px;
    }
  }
}

.scrollingFilterContainer {
  overflow-y: scroll;
  max-height: 200px;
}

.form-check {
  margin-left: 2.5rem;

  input {
    margin-left: 0px;
  }
}

.formContainer {
  .formButtons {
    margin-top: $spacer;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    button {
      margin-top: $spacer;
      max-width: $spacer * 50;
    }
  }
}

.formGroup {
  margin-right: 10px;
}
