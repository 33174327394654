@import "_variables.scss";
@import "./../../../node_modules/bootstrap/scss/mixins";
@import "./../../../node_modules/bootstrap/scss/bootstrap-grid";
@import "./../../../node_modules/bootstrap/scss/bootstrap-utilities";

$cover-width: 7rem;

.book-tile-wrapper {
  min-width: 100px;

  &.faded {
    opacity: 0.45;
  }

  .book-tile {
    position: relative;
    z-index: 1;

    .hitCount {
      background-color: red;
      position: absolute;
      right: 0px;
      border-radius: 5px;
      bottom: 0px;
      color: white;
      padding: 0.05rem;
      padding-right: 0.3rem;
      padding-left: 0.3rem;
      border: 1px solid $gray-400;
      margin: 2px;
    }

    @keyframes selectedTile {
      100% {
        box-shadow: 0 0 15px var(--box-shadow-color);
      }

      0% {
        box-shadow: 0 0 0px var(--box-shadow-color);
      }
    }

    &.searchMode {
      --box-shadow-color: transparent;

      &.selected {
        animation: selectedTile 500ms forwards;
        --box-shadow-color: var(--multiTitleSearchSelectedTitleColour);
      }
    }

    .card-body {
      @extend .p-0;
      height: 9rem;
      @extend .d-flex;
      @extend .flex-column;
    }

    .book-tile-info {
      overflow: hidden;
      position: relative;
      @extend .flex-fill;
      @extend .p-3;

      .fadeout {
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 82%, $light 100%);
        -webkit-user-select: none;
        user-select: none;
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      .card-text {
        font-size: 0.85rem;
        word-break: break-all;
      }
    }

    .span-header {
      vertical-align: middle;
      padding-left: 5px;
    }

    .book-tile-actions {
      @extend .d-flex;
      @extend .flex-row;
      @extend .align-items-center;
      @extend .flex-shrink-0;

      .nav {
        @extend .flex-shrink-0;
      }

      .book-tile-status {
        @extend .flex-fill;
        @extend .p-1;
        @extend .p-2;
        font-size: 0.75em;
      }

      .progress {
        height: 0.5rem;
      }
    }

    .book-tile-details {
      @extend .flex-shrink-0;
    }

    .cover {
      height: auto !important;
      width: $cover-width;
    }

    .conditionalFlag {
      position: absolute;
      right: -5px;
      top: -5px;
      transform: rotate(20deg);
      z-index: 2;

      svg {
        width: 25px;
      }
    }
  }
}

nav {
  .searchBar {
    animation: fadein 0.3s;
  }

  .searchButton {
    animation: fadein 0.3s;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}

.emptyLib {
  margin: $spacer;
}

.listContainer {
  position: relative;
}

.dropdownContainer {
  margin-bottom: 5px;
  width: 100%;

  .dropdown {
    button {
      padding-left: 0px;
      width: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;

      .annotation-type {
        padding-left: 5px;
        overflow: hidden;
        width: 100%;
      }
    }

    .dropdown-item {
      padding: 0px;

      div {
        padding-left: 5px;
        padding-right: 5px;
      }

      span {
        white-space: pre-wrap;
      }
    }

    .dropdown-item :hover {
      background-color: var(--accentColour);
      color: var(--accentInvert);
    }

    .dropdown-menu {
      width: 100%;
      padding: 0px;
    }
  }
}

.colourPickerContainer {
  margin-bottom: 10px;
  max-width: 300px;
}

.cover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: $bg-void;
  width: calc($cover-width * 0.7);
  height: calc($cover-width * 0.7 * 4 / 3);

  @include media-breakpoint-up(md) {
    & {
      width: $cover-width;
      height: calc($cover-width * 4 / 3);
    }
  }
}

.font-smallest,
.font-smallest > * {
  font-size: $font-size-smallest !important;
}

.font-smaller,
.font-smaller > * {
  font-size: $font-size-smaller !important;
}

.font-medium,
.font-medium > * {
  font-size: $font-size-medium !important;
}

.font-larger,
.font-larger > * {
  font-size: $font-size-larger !important;
}

.font-largest,
.font-largest > * {
  font-size: $font-size-largest !important;
}

.details-view .drawer-content {
  @extend .shadow;
  @extend .border;
  @extend .border-bottom-0;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  width: 90%;

  .details-view-header {
    @extend .p-3;
    @extend .border-bottom;

    .cover {
      @extend .shadow-sm;
    }
  }

  .details-view-body {
    @extend .p-3;

    a.list-group-item {
      color: var(--infoHeaderColour);
    }
  }

  @include media-breakpoint-up(md) {
    & {
      width: 90%;
    }
  }

  @include media-breakpoint-up(lg) {
    & {
      width: 80%;
    }
  }

  @include media-breakpoint-up(xl) {
    & {
      width: 80%;
      max-width: 1000px;
    }
  }
}

.hide {
  visibility: hidden;
  display: none !important;
}

.noWidth {
  width: 0;
}

.library-name-container {
  text-overflow: ellipsis;
  overflow: hidden;
}

.unbound-form-modal {
  width: 100%;
  height: 100%;
  top: 0;
  background: black;
  position: absolute;
  opacity: 0.3;
}

.unbound-form-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: $spacer;
  width: 75%;
  display: flex;
  flex-flow: column;

  @include media-breakpoint-up(md) {
    width: 75%;
    max-width: 700px;
    max-height: 800px;
  }

  @include media-breakpoint-up(sm) {
    max-height: 90%;
  }

  border: 1px solid $gray-400;
  max-height: 500px;
  overflow: visible;

  .ucContainer {
    overflow: auto;
  }

  .colour-token {
    height: 15px;
    width: 15px;
    border-radius: 15px;
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle;
    border-top: 1px solid;
    border-color: $gray-400;
  }

  .type-name {
    vertical-align: middle;
    margin-left: 5px;
  }

  .dropdownContainer {
    .dropdown-menu {
      max-height: 200px;
      overflow: scroll;
    }
  }
}

.modalHide {
  visibility: hidden;
  display: none;
  opacity: 0;
  transition:
    visibility 0.2s,
    opacity 0.2s linear;
}

.invalidQuery {
  border: solid red !important;
  border-width: 1px 3px !important;
}

.section {
  background-color: $gray-100;
  border-radius: $spacer;
  padding: $spacer;
  margin-top: calc($spacer/2);
  border-top: 2px solid #0975bb;
  border-left: 2px solid $gray-300;
  border-right: 2px solid $gray-300;
  border-bottom: 2px solid $gray-300;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.dataTable {
  width: 95%;
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  .dataHeaderRow {
    display: flex;
    border-radius: $spacer $spacer 0 0;
    overflow: hidden;

    .dataHeader {
      text-align: center;
      border: 1px solid $gray-300;
      flex: 1;
      background-color: $gray-400;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      * {
        margin-left: calc($spacer/2);
        margin-right: calc($spacer/2);
      }
    }
  }

  .scrollable {
    overflow-x: hidden; // Hides the horizontal scrollbar caused by the -4px margin below
    overflow-y: scroll;
  }

  .data {
    height: 100%;
    border: 1px solid $gray-300;

    .dataRow {
      display: flex;

      .dataItem {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 0;

        &.centerText {
          text-align: center;
        }

        &.bolded {
          font-weight: 800;
        }

        &.rightBorder {
          border-right: 1px solid $gray-400;
        }

        &.leftBorder {
          border-left: 1px solid $gray-400;
        }

        &.checkBox {
          text-align: center;

          .form-check-input {
            position: relative;
            margin: 0 auto;
          }
        }

        * {
          margin-left: calc($spacer/2);
          margin-right: calc($spacer/2);
        }
      }

      &:nth-child(even) {
        background-color: $gray-100;
      }

      &:nth-child(odd) {
        background-color: $gray-300;
      }

      &:hover {
        background-color: $gray-500;

        &.selected {
          background-color: lighten($accent, 10%);
        }
      }

      &.selected {
        background-color: $accent;
        color: white;
      }
    }

    .cogniflow-segments {
      margin-right: -5px; // Line up the column separators and ignore scrollbar
    }
  }

  .addRowButton {
    position: absolute;
    bottom: 15px;
    right: 10px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: $accent;
    border: 3px solid $gray-500;
    vertical-align: middle;

    svg {
      fill: white;
      height: 75%;
      width: 75%;
      margin: 5px;
    }
  }

  .deleteRowButton {
    position: absolute;
    bottom: 15px;
    right: 55px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: red;
    border: 3px solid $gray-500;
    vertical-align: middle;

    svg {
      fill: white;
      height: 75%;
      width: 75%;

      path {
        fill: white;
      }

      margin: 4px;
    }

    &.disabled {
      background-color: $gray-500;
    }
  }

  .searchButtonContainer {
    display: flex;
    flex-direction: row;

    .searchBarContainer {
      position: absolute;
      right: 45px;
      bottom: 0px;
      opacity: 0;
      -webkit-transition: opacity 0.2s ease-in-out;
      -moz-transition: opacity 0.2s ease-in-out;
      transition: opacity 0.2s ease-in-out;

      &.visible {
        display: block;
        opacity: 1;
      }

      .searchBar {
        position: absolute;
        bottom: 16px;
        right: 120px;
        width: 250px;
      }
    }

    .searchButton {
      position: absolute;
      bottom: 15px;
      right: 100px;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background-color: $accent;
      border: 3px solid $gray-500;
      vertical-align: middle;

      svg {
        fill: white;
        height: 75%;
        width: 75%;

        path {
          fill: white;
        }

        margin: 5px;
      }

      &.cancellable {
        background-color: red;
      }
    }
  }

  .addRowButton:hover {
    box-shadow: 0 0 6px 3px $accent;
  }

  .searchButton:hover {
    box-shadow: 0 0 6px 3px $accent;

    &.cancellable {
      box-shadow: 0 0 6px 3px red;
    }
  }

  .deleteRowButton:hover {
    box-shadow: 0 0 6px 3px red;

    &.disabled {
      box-shadow: 0 0 6px 3px $gray-500;
    }
  }
}

.subscriptionForm {
  z-index: 2000;

  .form-container {
    display: flex;
    flex-direction: column;
    #subscriptionTabs-tabpane-2 // The tab containing the data tables
    {
      height: 100%;
    }

    .subscription-tabs {
      flex: 1;
      position: relative;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;

      a {
        flex: 1;
        text-align: center;
      }

      .tab-content {
        overflow-y: scroll;
        flex: 1;
        position: relative;
        margin-top: 10px;
      }

      .subscriptionProperties {
        .thumbnailTitles {
          flex: 1;
          display: flex;

          span {
            flex: 1;
          }
        }

        .thumbnails {
          flex: 1;
          display: flex;

          div {
            flex: 1;

            img {
              max-width: 150px;
              height: auto;
            }
          }
        }
      }

      .subscriptionLicenses {
        display: flex;
        flex-direction: column;
        padding-bottom: $spacer;

        .licenceTable {
          flex: 1;
          display: flex;
          flex-direction: column;

          > div {
            flex: 1;
          }
        }
      }
    }
  }
}

// Dashboard Views
.mainView {
  width: 100%;
  height: 100%;
  // display: flex;
  overflow: scroll;

  .pubSelect {
    width: 250px;
    margin-bottom: $spacer;
  }

  .homeView {
    padding: calc($spacer/2);
    overflow: scroll;
  }

  .accountManagementView {
    .mb-3 {
      margin-left: 5px;
      margin-right: 5px;
    }

    .accountManagementViewInner {
      padding: calc($spacer/2);
      overflow: scroll;
    }

    .drawer {
      height: 90%;
    }

    .drawer-content {
      max-width: 1500px;
      width: 80%;

      .form-container {
        display: flex;
        flex-direction: column;

        .account-tabs {
          flex: 1;
          position: relative;
          overflow-y: scroll;
          display: flex;
          flex-direction: column;

          a {
            flex: 1;
            text-align: center;
          }

          .tab-content {
            overflow-y: scroll;
            flex: 1;
            position: relative;
            margin-top: 10px;
          }

          .accountProperties {
            .thumbnailTitles {
              flex: 1;
              display: flex;

              span {
                flex: 1;
              }
            }

            .thumbnails {
              flex: 1;
              display: flex;

              div {
                flex: 1;

                img {
                  max-width: 150px;
                  height: auto;
                }
              }
            }
          }
        }
      }
    }
  }

  .reportingView {
    .reportingViewInner {
      padding: calc($spacer/2);
      overflow: scroll;

      .statsHolder {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        span {
          padding: 5px;
          border: 1px solid $gray-400;
          flex: 1;
          margin-top: 5px;
        }
      }

      .innerGraphSection {
        display: flex;
        flex-direction: column;
        flex: 1;

        .graphTitles {
          display: flex;
          flex-direction: row;
          flex: 1;

          .titleItem {
            flex: 1;
          }
        }

        .graphDescription {
          flex: 1;

          p {
            font-style: italic;
          }
        }
      }

      .graphHolder {
        display: flex;
        flex-direction: row;
        flex: 1;

        .graphBox {
          flex: 1;
          min-height: 300px;
        }
      }
    }
  }

  .licensingView {
    .licencingViewInner {
      padding: calc($spacer/2);
      overflow: scroll;
    }

    .drawer {
      height: 90%;
    }

    .drawer-content {
      max-width: 1500px;
      width: 80%;
    }
  }

  .customerView {
    .customerViewInner {
      padding: calc($spacer/2);
      overflow: scroll;
    }

    .drawer {
      height: 90%;
    }

    .drawer-content {
      max-width: 1500px;
      width: 80%;

      .form-container {
        display: flex;
        flex-direction: column;
        #customerTabs-tabpane-1 // The tab containing the data tables
        {
          height: 100%;
        }

        .customer-tabs {
          flex: 1;
          position: relative;
          overflow-y: scroll;
          display: flex;
          flex-direction: column;

          a {
            flex: 1;
            text-align: center;
          }

          .tab-content {
            overflow-y: scroll;
            flex: 1;
            position: relative;
            margin-top: 10px;
            padding: calc($spacer/3);
          }

          .customerSubscriptions {
            display: flex;
            flex-direction: column;
            padding-bottom: $spacer;

            .subscriptionsTable {
              flex: 1;
              display: flex;
              flex-direction: column;

              > div {
                flex: 1;
              }
            }

            .dataTable {
              display: flex;
              flex-direction: column;
              width: 100%;

              .data {
                flex: 1;
                height: initial;
              }
            }
          }
        }
      }
    }
  }

  .productView {
    .productViewInner {
      padding: calc($spacer/2);
      overflow: scroll;
    }

    .drawer {
      height: 90%;
    }

    .drawer-content {
      max-width: 1500px;
      width: 80%;

      .form-container {
        display: flex;
        flex-direction: column;
        #productTabs-tabpane-2 // The tab containing the data tables
        {
          height: 100%;
        }

        .product-tabs {
          flex: 1;
          position: relative;
          overflow-y: scroll;
          display: flex;
          flex-direction: column;

          a {
            flex: 1;
            text-align: center;
          }

          .tab-content {
            overflow-y: scroll;
            flex: 1;
            position: relative;
            margin-top: 10px;
          }

          .productProperties {
            .thumbnailTitles {
              flex: 1;
              display: flex;

              span {
                flex: 1;
              }
            }

            .thumbnails {
              flex: 1;
              display: flex;

              div {
                flex: 1;

                img {
                  max-width: 150px;
                  height: auto;
                }
              }
            }
          }

          .productTitles {
            display: flex;
            flex-direction: column;
            padding-bottom: $spacer;

            .tableTitle {
              padding-left: $spacer;
              font-weight: 800;
            }
          }
        }

        .promoProperties {
          display: flex;
          flex-direction: column;

          .dataTable {
            flex: 1;
            width: initial;
          }
        }
      }
    }
  }

  .tipView {
    .tipsViewInner {
      padding: calc($spacer/2);
      overflow: scroll;
    }

    .drawer {
      height: 90%;
    }

    .drawer-content {
      max-width: 1500px;
      width: 80%;

      .form-container {
        display: flex;
        flex-direction: column;

        .tip-tabs {
          flex: 1;
          position: relative;
          overflow-y: scroll;
          display: flex;
          flex-direction: column;

          a {
            flex: 1;
            text-align: center;
          }

          .tab-content {
            overflow-y: scroll;
            flex: 1;
            position: relative;
            margin-top: 10px;
          }
        }
      }
    }
  }

  .billingView {
    .billingViewInner {
      padding: calc($spacer/2);
      overflow: scroll;
    }

    .drawer {
      height: 90%;
    }

    .drawer-content {
      max-width: 1500px;
      width: 80%;

      .form-container {
        display: flex;
        flex-direction: column;

        .billing-tabs {
          flex: 1;
          position: relative;
          overflow-y: scroll;
          display: flex;
          flex-direction: column;

          a {
            flex: 1;
            text-align: center;
          }

          .tab-content {
            overflow-y: scroll;
            flex: 1;
            position: relative;
            margin-top: 10px;
          }
        }
      }
    }
  }

  .emailTemplateView {
    padding: calc($spacer/2);
    overflow: scroll;
  }

  .subscriptionView {
    .subscriptionViewInner {
      padding: calc($spacer/2);
      overflow: scroll;
    }

    .drawer {
      height: 90%;
    }

    .drawer-content {
      max-width: 1500px;
      width: 80%;
    }
  }

  .feedbacksView {
    .feedbacksViewInner {
      padding: calc($spacer/2);
      overflow: scroll;
    }

    .drawer {
      height: 90%;
    }

    .drawer-content {
      max-width: 1500px;
      width: 80%;

      .form-container {
        display: flex;
        flex-direction: column;

        .feedback-tabs {
          flex: 1;
          position: relative;
          overflow-y: scroll;
          display: flex;
          flex-direction: column;

          a {
            flex: 1;
            text-align: center;
          }

          .tab-content {
            overflow-y: scroll;
            flex: 1;
            position: relative;
            margin-top: 10px;
          }
        }
      }
    }
  }

  .systemView {
    .systemViewInner {
      padding: calc($spacer/2);
      overflow: scroll;
    }

    .drawer {
      height: 90%;
    }

    .drawer-content {
      max-width: 1500px;
      width: 80%;
    }
  }

  .bulletinsView {
    .bulletinsViewInner {
      padding: calc($spacer/2);
      overflow: scroll;
    }

    .drawer {
      height: 90%;
    }

    .drawer-content {
      max-width: 1500px;
      width: 80%;

      .form-container {
        display: flex;
        flex-direction: column;

        .bulletin-tabs {
          flex: 1;
          position: relative;
          overflow-y: scroll;
          display: flex;
          flex-direction: column;

          a {
            flex: 1;
            text-align: center;
          }

          .tab-content {
            overflow-y: scroll;
            flex: 1;
            position: relative;
            margin-top: 10px;
          }
        }
      }
    }
  }

  .announcementView {
    .announcementViewInner {
      padding: calc($spacer/2);
      overflow: scroll;
    }

    .drawer {
      height: 90%;
    }

    .drawer-content {
      max-width: 1500px;
      width: 80%;

      .form-container {
        display: flex;
        flex-direction: column;

        .tip-tabs {
          flex: 1;
          position: relative;
          overflow-y: scroll;
          display: flex;
          flex-direction: column;

          a {
            flex: 1;
            text-align: center;
          }

          .tab-content {
            overflow-y: scroll;
            flex: 1;
            position: relative;
            margin-top: 10px;
          }
        }
      }
    }
  }

  .panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $gray-100;
    display: flex;
    flex-direction: column;
    height: fit-content;
    margin: $spacer;
    border-radius: $spacer;
    max-height: 90%;
    min-width: fit-content;
    overflow-y: scroll;
    overflow-x: hidden;

    &.disabled {
      opacity: 0.5;
    }

    .item {
      &:first-child {
        padding-top: $spacer;
        border-radius: $spacer $spacer 0 0;
      }

      &:last-child {
        padding-top: $spacer;
        border-radius: 0 0 $spacer $spacer;
      }

      &:first-child:last-child {
        padding-top: $spacer;
        border-radius: $spacer;
      }

      &.selected {
        background-color: $gray-400;
      }

      flex-direction: column;
      align-items: center;
      display: flex;

      &:hover {
        background-color: $gray-300;
      }

      .icon {
        width: 65.68px;
        height: 65.05px;
        margin: 4px;
        left: 11px;
        border-radius: 32px;
        border: 2px solid #ffffff;
        background-color: inherit;
      }

      .publisher-name {
        text-align: center;
        width: 95px;
        font-weight: 400;
        padding: 4px;
        font-size: 15px;
      }
    }

    .addDiv {
      width: 65.68px;
      height: 65.05px;
      margin: 4px;
      left: 11px;
      border-radius: 32px;
      border: 2px solid #ffffff;
      background-color: inherit;
      padding: $spacer;
    }
  }

  .publisherView {
    display: flex;
    padding: calc($spacer/2);
    justify-content: space-between;
    height: 100%;
    width: 100%;
    overflow-y: scroll;

    .tab-content {
      margin-top: $spacer;
    }

    .section {
      background-color: $gray-100;
      border-radius: $spacer;
      padding: $spacer;
      margin-top: calc($spacer/2);
      width: 100%;
      border-top: 2px solid #0975bb;
      border-left: 2px solid $gray-300;
      border-right: 2px solid $gray-300;
      border-bottom: 2px solid $gray-300;
      overflow: auto;
      display: flex;
      flex-direction: column;
    }

    .content-box {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      background: #ffffffed;
      border-top: 2px solid #0975bb;
      border-radius: $spacer;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding: calc($spacer/2);
      overflow-y: scroll;

      .header-box {
        display: flex;
        flex-direction: row;
        padding: $spacer;
      }

      .content-publisher-logo {
        border-radius: 40px;
        border: 2px solid #ffffff;
      }

      .content-publisher-name {
        padding: 20px;
        color: #093353;
        font-weight: 400;
      }

      .content-publisher-detail-header {
        color: #0975bb;
        font-weight: 600;
        padding: 2px;
      }

      .content-publisher-detail {
        color: #0975bb;
        font-weight: 400;
        padding: 1px;
        margin-left: 40px;
        width: 80%;
      }

      .content-button {
        margin: 25px;
        width: 183.42px;
        padding: 5px;
        height: 39.51px;
        left: 391.57px;
        top: 510.03px;
        font-size: 18px;
        line-height: 16px;
        color: #ffffff;
        background: #38c6d9;
        border-radius: 7px;
      }
    }

    #file-input,
    #json-input,
    #html-input,
    #library-input,
    #theme-input {
      cursor: pointer;
      outline: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      overflow: hidden;
      filter: alpha(opacity=0);
      /* IE < 9 */
      opacity: 0;
    }

    #logo-input {
      cursor: pointer;
      outline: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      overflow: hidden;
      filter: alpha(opacity=0);
      /* IE < 9 */
      opacity: 0;
    }

    .input-label {
      cursor: pointer;
      position: relative;
      display: inline-block;
    }
  }

  .version-tabs-root {
    display: flex;
    flex-direction: column;
  }

  .titleView {
    .titleViewInner {
      padding: calc($spacer/2);
      overflow: scroll;
    }

    .drawer {
      height: 90%;
    }

    .drawer-content {
      max-width: 1500px;
      width: 80%;

      .form-container {
        display: flex;
        flex-direction: column;

        .version-tabs {
          flex: 1;
          position: relative;
          overflow-y: scroll;
          display: flex;
          flex-direction: column;

          a {
            flex: 1;
            text-align: center;
          }

          .tab-content {
            overflow-y: scroll;
            flex: 1;
            position: relative;
            margin-top: 10px;
            padding-top: $spacer;
            padding-bottom: $spacer;
          }
        }
      }
    }
  }

  .storeFrontView {
    .storeFrontViewInner {
      padding: calc($spacer/2);
      overflow: scroll;
      h2 {
        margin-top: $spacer;
      }
    }

    .drawer {
      height: 90%;
    }

    .drawer-content {
      max-width: 1500px;
      width: 80%;
    }
    .storefrontForm {
      position: relative;
      display: flex;
      flex-direction: column;
      .tab-content {
        flex: 1;
        overflow-y: auto;
      }
    }
  }
  .choiceButton {
    top: $spacer;
    right: $spacer;
    position: absolute;
  }
  .copyLinkButton {
    top: $spacer * 5;
    right: $spacer;
    position: absolute;
  }
}

.sectionArea {
  display: flex;
  flex-direction: column;
  flex-grow: 4;
}

.section {
  background-color: $gray-100;
  border-radius: $spacer;
  padding: $spacer;
  margin-top: calc($spacer/2);
  width: 100%;
  border-top: 2px solid #0975bb;
  border-left: 2px solid $gray-300;
  border-right: 2px solid $gray-300;
  border-bottom: 2px solid $gray-300;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.content-box {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background: #ffffffed;
  border-top: 2px solid #0975bb;
  border-radius: $spacer;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: calc($spacer/2);
  overflow-y: scroll;
}

.content-publisher-logo {
  border-radius: 40px;
  border: 2px solid #ffffff;
}

.header-box {
  display: flex;
  flex-direction: row;
  padding: $spacer;
}

.content-publisher-name {
  padding: 6px;
  color: #093353;
  font-weight: 400;
}

.productTitles {
  display: flex;
  flex-direction: column;
  padding-bottom: $spacer;
}

.drawer {
  height: 90%;
}

.drawer-content {
  max-width: 1500px;
  width: 80%;
}

.titlesTab {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.profileView {
  display: flex;
  padding: calc($spacer/2);
  height: 100%;
  width: 100%;

  overflow-y: scroll;

  .sectionArea {
    display: flex;
    flex-direction: column;
    flex-grow: 4;
  }

  .section {
    background-color: $gray-100;
    border-radius: $spacer;
    padding: $spacer;
    margin-top: calc($spacer/2);
    width: 100%;
    border-top: 2px solid #0975bb;
    border-left: 2px solid $gray-300;
    border-right: 2px solid $gray-300;
    border-bottom: 2px solid $gray-300;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  .drawer {
    height: 90%;
  }

  .drawer-content {
    max-width: 1500px;
    width: 80%;
  }
}

// Master Layout
.masterPage-container {
  display: grid;

  grid-template-areas:
    "header header header"
    "sidebar content content"
    "footer footer footer";

  grid-template-columns: 225px 5fr;
  grid-template-rows: auto 12fr 1fr;
  grid-gap: 2px;
  height: 100%;
  width: 100%;

  .bookview-tabs {
    grid-area: content;
    overflow: auto;
  }

  .header {
    position: relative;
    grid-area: header;
    display: flex;
    align-items: center;
    background-color: $gray-100;
    padding: 25px;
    box-sizing: border-box;

    .header-logo {
      border: 1px solid rgba(124, 172, 213, 0.12);
    }

    .userBox {
      position: absolute;
      right: 0;
      display: flex;
      padding-right: $spacer;
      align-items: center;
      justify-content: space-evenly;
      min-width: 300px;

      .avatar {
        background-color: $gray-100;
        box-sizing: border-box;
        max-height: 80%;
      }

      .logoutBox {
        font-size: large;
        cursor: pointer;
      }
    }
  }

  .footer {
    grid-area: footer;
    background-color: $gray-100;
    padding: 20px;
    border: 1px solid rgba(124, 172, 213, 0.12);
    box-sizing: border-box;
    display: flex;
    justify-content: center;

    .footer-logo {
      grid-area: footer;
      background-color: $gray-100;
    }
  }

  .sidebar {
    grid-area: sidebar;

    .header {
      font-weight: 700;
      background-color: #c5d9ea;
    }

    .menu {
      display: flex;
      flex-direction: column;

      background-color: $gray-100;
      box-sizing: border-box;
      margin-top: $spacer;
      margin-left: calc($spacer/2);
      margin-right: calc($spacer/2);
      border-radius: $spacer;

      .menuItem {
        width: 100%;
        height: 35px;
        display: flex;
        padding-left: $spacer;
        padding-right: $spacer;
        align-items: center;

        &.selectedItem {
          background-color: $gray-400;
        }

        .itemIcon {
          margin-right: $spacer * 2;
        }
      }

      .menuItem:last-child {
        border-radius: 0 0 $spacer $spacer;
      }

      .menuItem:first-child {
        border-radius: $spacer $spacer 0 0;
      }

      .menuItem:hover {
        background-color: $gray-300;
      }
    }
  }
}

.darkOverlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(108, 117, 125, 0.3);
  z-index: $zindex-modal-backdrop;
}

.loginView {
  overflow-y: scroll;

  .ssoLoginButton {
    margin: 0.9rem auto;
    height: 3rem;
    width: auto;

    .ssoLoginButtonImage {
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.inviteView {
  background-color: white;
  z-index: $zindex-modal;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 80%;
  height: 35%;
  max-width: 1050px;
  max-height: 670px;
  min-height: 465px;

  .productImage {
    max-width: 200px;
    height: auto;
    margin: 7px;
    border: 1px solid;
  }

  .loading-modal-context {
    width: 100%;
    height: 100%;
  }

  .permissionInviteContainer {
    display: flex;
    flex-direction: row;
    border: 1px solid $gray-400;
    width: 100%;
    height: 100%;

    .permissionDescription {
      flex: 1;
      padding: $spacer;
      height: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
    }

    .loginInfo {
      flex: 1;

      .loggedIn {
        padding: $spacer;

        button {
          flex-grow: 1;
          margin: calc($spacer/2);
        }
      }
    }
  }
}

.accountConfirmView {
  background-color: white;
  z-index: $zindex-modal;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 525px;
  max-height: 225px;
  height: 30%;

  .loading-modal-context {
    width: 100%;
    height: 100%;
  }

  .confirmContainer {
    display: flex;
    flex-direction: column;
    padding: $spacer;
    height: 100%;
    width: 100%;

    .confirmText {
      flex: 1;
    }
  }
}

.confirmPasswordResetView {
  background-color: white;
  z-index: $zindex-modal;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 525px;
  max-height: 225px;
  height: 30%;

  .loading-modal-context {
    width: 100%;
    height: 100%;
  }

  .resetContainer {
    display: flex;
    flex-direction: column;
    padding: $spacer;
    height: 100%;
    width: 100%;

    .confirmText {
      flex: 1;
    }
  }
}
.deleteAccountView {
  background-color: white;
  z-index: $zindex-modal;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 525px;
  height: auto;

  .loading-modal-context {
    width: 100%;
    height: 100%;
  }
  .center {
    display: flex;
    justify-content: center;
    margin-top: $spacer;
  }
  .resetContainer {
    display: flex;
    flex-direction: column;
    padding: $spacer;
    height: 100%;
    width: 100%;

    .confirmText {
      flex: 1;
    }
  }
}
.resetView {
  margin: $spacer;
}

.htmlEditor {
  width: 100%;
  min-height: 100pt;
  padding: $spacer;
}

.w-md-editor-toolbar {
  ul:last-of-type {
    margin-top: $spacer;
  }

  button {
    height: 25px;
    width: 25px;
  }

  svg {
    height: 15px;
    width: 15px;
  }
}

.aboutInfoForm {
  .formButtons {
    width: 100%;

    button {
      margin: calc($spacer / 2);
      width: 100%;
    }
  }

  .htmlInputs {
    label {
      margin-right: $spacer;
      cursor: pointer;
      color: blue;
      text-decoration: underline;
    }

    button {
      margin-left: $spacer;
    }
  }
}

.headerAboutInfo {
  margin: $spacer;
}

.titleInput {
  margin: 0;
}

.htmlInput {
  margin: 0;
}

.internationalizationsDrawer {
  .drawer-content {
    padding: calc($spacer / 3) !important;
  }
}
.detailHeader {
  font-weight: 800;
}

.globalNoteDetails {
  margin-bottom: $spacer;
}

.markdownBlockContainer * {
  user-select: text;
}
.mdxeditor {
  margin-top: $spacer;
  height: 26rem;
  overflow-y: scroll;
}
.editableMarkdownContent {
  overflow: auto;
  border: 1px solid $gray-400;
  min-height: 20rem;
  height: fit-content;
  * {
    user-select: text !important;
  }
}

.mdxeditor-toolbar {
  flex-wrap: wrap;
  width: 100%;
  overflow: auto;
}

.consentSwitch {
  margin-left: 1.5rem;
}

.mdxeditor-popup-container {
  z-index: 2000 !important;
}

.salesFeedList {
  list-style-type: none;
  padding: 0;
  overflow: hidden;
  border: 1px solid gray;
  background-color: white;
}

.recentPurchasesHeader {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  text-align: center; /* Center the headers */
}

.listingHeader,
.creditsHeader,
.purchasedHeader,
.listingName,
.credits {
  flex: 1;
  text-align: center; /* Center the displayed values */
}

.saleItem {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.listingName,
.credits,
.timeAgo {
  flex: 1;
  text-align: center;
  animation: pulse 1s infinite alternate;
}

@keyframes pulse {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}

.saleItem {
  transition: transform 0.3s ease;
  color: black;
}

.termHolder {
  display: flex;
  flex-direction: row;
  .termContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: calc($spacer/2);
  }
  .termContainer:not(:first-child) {
    margin-left: $spacer;
  }
  .termActiveLabel {
    margin-left: 1.25rem;
  }
  .termActive {
    border: green 2px solid;
  }
  .priceDetailsLabel {
    cursor: pointer;
  }
}

.divWhiteSpacer {
  width: 100%;
  height: 10rem;
}
