.cogniflow-loading {
  $loading-height: 5px;

  position: absolute;
  height: $loading-height;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1;
  &.cogniflow-loading-top {
    top: 0;
  }

  &.cogniflow-loading-bottom {
    bottom: 0;
  }

  > div {
    background-color: #1c67d8;
    height: $loading-height;
    width: 0%;
    margin: 0 auto;
    animation: cogniflow-loading-animation 1s linear infinite;
  }

  @keyframes cogniflow-loading-animation {
    0% {
      width: 5%;
    }
    50% {
      width: 100%;
    }
    100% {
      width: 5%;
    }
  }
}

.cogniflow {
  .cogniflow--repository {
    z-index: -100000;
    pointer-events: none;
    opacity: 0;
    height: 0;
    width: 100%;
    overflow: hidden;
  }

  .cogniflow--transition {
    display: none;
  }
}
