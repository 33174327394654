@import "_views.scss";

#root,
#appLayout {
  height: 100%;
  width: 100%;

  .menu-drawer {
    width: 350px;
    max-width: 95%;
    z-index: 1050;
  }
}

.vertical-split-group {
  @extend .d-flex;

  > div {
    @extend .flex-fill;
    @extend .d-flex;
    @extend .flex-row;
  }
}

.gutter.gutter-horizontal {
  cursor: col-resize;
  @extend .border-start;
  @extend .border-end;
  @extend .bg-light;
}

.bookview > .vertical-split-group {
  @extend .flex-fill;
  position: relative;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.bookview-tabs {
  @extend .full-height;

  > .tab-content {
    @extend .full-height;

    > .tab-pane {
      @extend .full-height;
    }
  }
}

.tab-pane {
  position: relative;
}

.initial-app-loading {
  &.loading-modal {
    z-index: $zindex-initialLoading !important;
  }
}

.book-tab-close {
  display: inline-block;
  width: 15px;
  margin-right: 5px;
  margin-left: -8px;

  &:hover {
    svg {
      background-color: transparentize($color: red, $amount: 0.5);
      border: 1px solid;
      padding: 1px;
    }
  }
}

.book-tab-name {
  vertical-align: middle;
}

.tab-spinner {
  width: $spacer * 0.9;
  height: $spacer * 0.9;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 13px;
  margin-right: 7px;
  color: var(--accentTextColour);
}

.book-tab-container {
  position: relative;
}

.scrollable {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.flex-fill.scrollable {
  height: 0;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content-reader {
  max-width: 65rem;
  margin: 0 auto;
  background-color: $white;
  height: 1px;
  min-height: 100%;
}

.content-reader-unbound {
  margin: 0 auto;
  background-color: $white;
  width: 100%;
  height: 1px;
  min-height: 100%;
}

.content-reader-container {
  width: 100%;
  height: 100%;
}

.breakText {
  display: inline-block !important;
  word-break: break-word !important;
}

.bookview-tabs {
  @extend .flex-fill;
}

.bookview-toggle-placeholder {
  visibility: hidden;
  display: none;
  margin-left: -$spacer;

  .bookview-toggle-container {
    height: 0;
    position: static;
  }
}

.library-nav-bar {
  z-index: $zindex-firstNav;
}

.bookview .collapsible {
  overflow: hidden;
}

.scrolling-reader-container {
  overflow-y: scroll !important;
  -webkit-overflow-scrolling: touch !important;
  height: 100%;
  width: 100%;
  position: relative;
}

.bookview .collapsible.collapsed {
  display: none !important;

  & ~ .split-panel {
    width: 100% !important;

    .bookview-toggle-placeholder {
      display: block;
    }
  }

  & ~ .gutter.gutter-horizontal {
    display: none;
  }
}

@media (max-width: 768px) {
  .bookview > .vertical-split-group {
    overflow: hidden;
  }

  .bookview .collapsible {
    transition:
      transform 0.2s linear,
      box-shadow 0.2s linear;
    width: 100% !important;
    z-index: $zindex-baseline;
    bottom: 0;
    top: 0;
    position: absolute;
    transform: translate(0, 0);
    box-shadow: 5px 0 10px 0 rgba(0, 0, 0, 0.3);

    & ~ .split-panel {
      width: 100% !important;
    }

    &.collapsed {
      display: block !important;
      transform: translate(-100%, 0);
      box-shadow: 5px 0 10px 0 rgba(0, 0, 0, 0);
    }
  }
}

.first-navbar {
  transition: background-color 0.2s linear;

  &.has-light-bg {
    .icon svg {
      @include color-svg($navbar-light-color);
    }

    .library-selection-icon.active {
      box-shadow:
        $box-shadow-sm,
        0 1px 0 0 rgba(0, 0, 0, 0.5),
        0 0 0 4px rgba($dark, 0.5);
    }
  }

  .add-library {
    padding: 5px;
    border: 1px solid white;

    svg {
      margin: 5px;
    }
  }
}

.wrap-grid {
  @extend .d-flex;
  @extend .flex-wrap;

  > * {
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    > * {
      width: 50%;
    }
  }

  @include media-breakpoint-up(lg) {
    > * {
      width: 33%;
    }
  }

  @include media-breakpoint-up(xl) {
    > * {
      width: 25%;
    }
  }
}

@include media-breakpoint-down(lg) {
  .collapsed-navbar {
    display: none !important;
  }
}

@include media-breakpoint-up(xl) {
  .btn-fab {
    display: none;
  }
}

.bottomSpacer {
  width: 100%;
  height: 5em;
}

.titleButton {
  margin-left: 70%;
  margin-bottom: 5px;
  margin-top: 5px;
}
.mdxeditor-popup-container {
  z-index: 2000;
}
